import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  userShowBasic: `on UserShow {
    id
    userId
    showId
    expiresOn
    createdAt
    updatedAt
  }`,
});

const createMutation = graph.mutate(`($input: CreateUserShowInput!) {
  createUserShow(input: $input) {
    userShow { ...userShowBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateUserShowInput!) {
  updateUserShow(input: $input) {
    userShow { ...userShowBasic }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteUserShowInput!) {
  deleteUserShow(input: $input) { userShowId }
}`);

// endregion
export function create({ userShow }) {
  return createMutation({ input: userShow })
    .catch(wrapGraphQLError)
    .then(result => result.createUserShow);
}

export function update({ userShowId, updates }) {
  return updateMutation({ input: { userShowId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateUserShow);
}

function _delete({ userShowId }) {
  return deleteMutation({ input: { userShowId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteUserShow);
}

export { _delete as delete };

import { graph, paramsWithRole } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  showRoleBasic: `on ShowRole {
    id
    showId
    actorId
    roleName
    understudy
    details
    contractDetails @include(if: $withContractDetails)
    contractDetailsUpdatedAt @include(if: $withContractDetails)
    filters @include(if: $withFilters) {
      ...filterSetActor
    }
    status
    position
    createdAt
    updatedAt
  }`,
  showRoleForActor: `on ShowRole {
    id
    showId
    actorId
    roleName
    understudy
    status
    show {
      ...showForActor
    }
    createdAt
    updatedAt
  }`,
  showRoleFull: `on ShowRole {
    ...showRoleBasic
    show @include(if: $withShows) {
      ...showBasic
    }
    actor @include(if: $withActors) {
      ...actorBasic
    }
    prospects @include(if: $withProspects) {
      ...showRoleProspectBasic
    }
  }`,
});

const retrieveQuery = graph.query(`($showRoleId: ShowRoleID!, $withProspects: Boolean = false, $withActors: Boolean = false, $withShows: Boolean = false, $withFilters: Boolean = false, $withContractDetails: Boolean = false) {
  showRole(showRoleId: $showRoleId) {
    ...showRoleFull
  }
}`);

const createMutation = graph.mutate(`($input: CreateShowRoleInput!, $withFilters: Boolean = false, $withContractDetails: Boolean = false) {
  createShowRole(input: $input) {
    showRole { ...showRoleBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateShowRoleInput!, $withActors: Boolean = false, $withFilters: Boolean = false, $withContractDetails: Boolean = false) {
  updateShowRole(input: $input) {
    showRole { ...showRoleBasic }
    showRoleProspect { ...showRoleProspectBasic }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderShowRoleInput!) {
  reorderShowRole(input: $input) {
    showRole { id position }
    updatedShowRoles { id position }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteShowRoleInput!) {
  deleteShowRole(input: $input) { showRoleId }
}`);

// endregion
export function retrieve({ showRoleId, ...withOptions } = {}) {
  return retrieveQuery(paramsWithRole({ showRoleId, ...withOptions }))
    .catch(wrapGraphQLError)
    .then((data) => data.showRole);
}

export function create({ showRole, ...withOptions }) {
  return createMutation(paramsWithRole({ input: showRole, ...withOptions }))
    .catch(wrapGraphQLError)
    .then(result => result.createShowRole);
}

export function update({ showRoleId, updates, options = {} }) {
  return updateMutation(paramsWithRole({ input: { showRoleId, ...updates }, ...options }))
    .catch(wrapGraphQLError)
    .then(result => result.updateShowRole);
}

export function reorder({ showRoleId, position, relatedShowRoleId = null }) {
  return reorderMutation({ input: { showRoleId, position, relatedShowRoleId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderShowRole);
}

function _delete({ showRoleId }) {
  return deleteMutation({ input: { showRoleId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteShowRole);
}

export { _delete as delete };

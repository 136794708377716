import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useShowStore } from '@/stores/shows';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord, urlsExpired,
} from '@/utilities/helpers.js';
import { UploadManager } from '@/utilities/upload_manager';

export const useSeasonStore = defineStore('seasons', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (seasonId) => state.records.find((v) => v.id === seasonId),
    isLoading: (state) => state.loading > 0,
  },
  actions: {
    async processRecord(season) {
      return addUpdateRecord(this.records, season);
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(seasonId) {
      await useShowStore().removeRecordsBy({ seasonId });

      removeRecord(this.records, seasonId);
    },
    async retrieveAsync(seasonId, { force = false } = {}) {
      let record = this.records.find((v) => v.id === seasonId);
      if (!record || force) {
        await doLoading(this, async () => {
          record = await api.seasons.retrieve({ seasonId });
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async sync() {
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.seasons.list,
          node: 'seasons',
          filters: { limit: 100 },
        }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async retrievePosterUrls(seasonId) {
      let record = this.records.find((v) => v.id === seasonId);
      await doLoading(this, async () => {
        if (!record) {
          record = await api.seasons.retrieve({ seasonId });
          record = await this.processRecord(record);
        } else {
          if (urlsExpired(record.posterUrls)) {
            record = await api.seasons.retrievePosterUrls({ seasonId });
            record = await this.processRecord(record);
          }
        }
      });
      return record;
    },
    async startPosterUpload({ seasonId, contentType, fileName }) {
      const initial = await api.seasons.uploadPoster({
        seasonId, upload: { start: { contentType, fileName } },
      });
      await this.processRecord(initial.season);
      return new UploadManager(initial.presignedPost, async (completionToken) => {
        const data = await api.seasons.uploadPoster({ seasonId, upload: { finish: { completionToken } } });
        await this.processRecord(data.season);
      });
    },
    async reorder(seasonId, position, relatedSeasonId = null) {
      if (isExisting(seasonId)) {
        const payload = {
          seasonId,
          position,
        };
        if (relatedSeasonId) {
          payload.relatedSeasonId = relatedSeasonId;
        }
        return await doLoading(this, async () => {
          return await api.seasons.reorder(payload).then(async (data) => {
            await this.processRecords(data.updatedSeasons);
            return this.processRecord(data.season);
          });
        });
      } else {
        throw new Error('Season ID does not exist');
      }
    },
    async save(season) {
      if (isExisting(season.id)) {
        // existing
        const original = this.retrieve(season.id);
        const payload = {
          seasonId: season.id,
          updates: buildChanges(original, season, { skip: ['id'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.seasons.update(payload).then(async (data) => {
              return this.processRecord(data.season);
            });
          } else {
            return original;
          }
        });
      } else {
        // new season
        const prepared = {
          season: clearEmpty(season),
        };
        return await doLoading(this, async () => {
          return await api.seasons.create(prepared).then(async (data) => {
            return this.processRecord(data.season);
          });
        });
      }
    },
    async delete(seasonId) {
      const season = this.retrieve(seasonId);
      if (!season) {
        return;
      }
      const payload = { seasonId };

      return await doLoading(this, async () => {
        return await api.seasons.delete(payload).then(async data => {
          await this.removeRecord(data.seasonId);
          return data.seasonId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSeasonStore, import.meta.hot));
}

import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers.js';
import { useShowStore } from '@/stores/shows';

export const useUserShowStore = defineStore('userShows', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (userShowId) => state.records.find((v) => v.id === userShowId),
    isLoading: (state) => state.loading > 0,
    forUserId: (state) => (userId) => state.records.filter((v) => v.userId === userId),
  },
  actions: {
    async processRecord(userShow) {
      if (userShow.show) {
        await useShowStore().processRecord(userShow.show);
      }
      return addUpdateRecord(this.records, userShow, {
        skip: ['show'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(userShowId) {
      removeRecord(this.records, userShowId);
    },
    async save(userShow) {
      if (isExisting(userShow.id)) {
        // existing
        const original = this.retrieve(userShow.id);
        const payload = {
          userShowId: userShow.id,
          updates: buildChanges(original, userShow, { skip: ['id', 'updatedAt'] }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.userShows.update(payload).then(async (data) => {
              return this.processRecord(data.userShow);
            });
          } else {
            return original;
          }
        });
      } else {
        // new user shows
        const prepared = {
          userShow: clearEmpty(userShow),
        };
        return await doLoading(this, async () => {
          return await api.userShows.create(prepared).then(async (data) => {
            return this.processRecord(data.userShow);
          });
        });
      }
    },
    async delete(userShowId) {
      const userShow = this.retrieve(userShowId);
      if (!userShow) {
        return;
      }
      const payload = { userShowId };

      return await doLoading(this, async () => {
        return await api.userShows.delete(payload).then(async data => {
          await this.removeRecord(data.userShowId);
          return data.userShowId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserShowStore, import.meta.hot));
}

import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphql
graph.fragment({
  showRoleProspectBasic: `on ShowRoleProspect {
    id
    showRoleId
    actorId
    position
    status
    createdAt
    updatedAt
    actor @include(if: $withActors) {
      ...actorBasic
    }
  }`,
  showRoleProspectForActor: `on ShowRoleProspect {
    id
    showRoleId
    actorId
    status
    showRole {
      ...showRoleForActor
    }
  }`,
  showRoleProspectFull: `on ShowRoleProspect {
    ...showRoleProspectBasic
    notes @include(if: $withProspectNotes) {
      ...showRoleProspectNoteFull
    }
  }`,
});

const retrieveQuery = graph.query(`($showRoleProspectId: ShowRoleProspectID!, $withActors: Boolean = false, $withProspectNotes: Boolean = false) {
  showRoleProspect(showRoleProspectId: $showRoleProspectId) {
    ...showRoleProspectFull
  }
}`);

const createMutation = graph.mutate(`($input: CreateShowRoleProspectInput!, $withActors: Boolean = false) {
  createShowRoleProspect(input: $input) {
    showRoleProspect { ...showRoleProspectBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateShowRoleProspectInput!, $withActors: Boolean = false) {
  updateShowRoleProspect(input: $input) {
    showRoleProspect { ...showRoleProspectBasic }
  }
}`);

const reorderMutation = graph.mutate(`($input: ReorderShowRoleProspectInput!) {
  reorderShowRoleProspect(input: $input) {
    showRoleProspect { id position }
    updatedShowRoleProspects { id position }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteShowRoleProspectInput!) {
  deleteShowRoleProspect(input: $input) { showRoleProspectId }
}`);

// endregion

export function retrieve({ showRoleProspectId, ...withOptions } = {}) {
  return retrieveQuery({ showRoleProspectId, ...withOptions })
    .catch(wrapGraphQLError)
    .then((data) => data.showRoleProspect);
}

export function create({ showRoleProspect: showRoleProspect }) {
  return createMutation({ input: showRoleProspect })
    .catch(wrapGraphQLError)
    .then(result => result.createShowRoleProspect);
}

export function update({ showRoleProspectId, updates }) {
  return updateMutation({ input: { showRoleProspectId, ...updates } })
    .catch(wrapGraphQLError)
    .then(result => result.updateShowRoleProspect);
}

export function reorder({ showRoleProspectId, position, relatedShowRoleProspectId = null }) {
  return reorderMutation({ input: { showRoleProspectId, position, relatedShowRoleProspectId } })
    .catch(wrapGraphQLError)
    .then(result => result.reorderShowRoleProspect);
}

function _delete({ showRoleProspectId }) {
  return deleteMutation({ input: { showRoleProspectId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteShowRoleProspect);
}

export { _delete as delete };

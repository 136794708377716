import kebabCase from 'lodash/kebabCase';

const LABELS = {};

function addEnum(statusEnum, value, label) {
  if (!LABELS[statusEnum]) LABELS[statusEnum] = {};
  LABELS[statusEnum][value] = { id: value, label };
}

// TODO Auto generate from rust source?
addEnum('ActorStatus', 'IN_WIZARD', 'Incomplete');
addEnum('ActorStatus', 'IN_REVIEW', 'In Review');
addEnum('ActorStatus', 'ACTIVE', 'Active');
addEnum('RoleTypeFilter', 'MALE_PRESENTING_ROLE', 'Male Presenting');
addEnum('RoleTypeFilter', 'FEMALE_PRESENTING_ROLE', 'Female Presenting');
addEnum('RoleTypeFilter', 'NON_BINARY_ROLE', 'Non-Binary role');
addEnum('HeightComparatorFilter', 'GREATER_THAN_OR_EQUAL', 'Greater than');
addEnum('HeightComparatorFilter', 'LESS_THAN_OR_EQUAL', 'Less than');
addEnum('ShowRoleProspectStatus', 'UNREVIEWED', 'Unreviewed');
addEnum('ShowRoleProspectStatus', 'POSSIBLE', 'Possible');
addEnum('ShowRoleProspectStatus', 'REJECTED', 'NTS');
addEnum('ShowRoleProspectStatus', 'DECLINED', 'Declined');
addEnum('ShowRoleProspectStatus', 'UNAVAILABLE', 'Unavailable');
addEnum('ShowRoleStatus', 'OPEN', 'Open');
addEnum('ShowRoleStatus', 'READY', 'Ready');
addEnum('ShowRoleStatus', 'OFFERED', 'Offered');
addEnum('ShowRoleStatus', 'ACCEPTED', 'Accepted');
addEnum('ShowRoleStatus', 'NOT_NEEDED', 'Not needed');
addEnum('ShowStatus', 'PLANNING', 'Planning');
addEnum('ShowStatus', 'ACCEPTING_APPLICATIONS', 'Accepting applications');
addEnum('ShowStatus', 'FULLY_CAST', 'Fully cast');
addEnum('ShowStatus', 'RUNNING', 'Running');
addEnum('ShowStatus', 'COMPLETED', 'Completed');
addEnum('ShowStatus', 'CANCELED', 'Canceled');
addEnum('ActorAffiliation', 'AEA', 'AEA');
addEnum('ActorAffiliation', 'NON_UNION', 'Non-Union');
addEnum('ActorSkillRank', 'ACTING', 'Actor');
addEnum('ActorSkillRank', 'DANCING', 'Dancer');
addEnum('ActorSkillRank', 'SINGING', 'Singer');
addEnum('PrefabFilterModel', 'ACTOR', 'Actor');
addEnum('PrefabFilterModel', 'SHOW', 'Show');
addEnum('UserRole', 'ADMIN', 'Owner');
addEnum('UserRole', 'MANAGER', 'Admin');
addEnum('UserRole', 'THEATER_MANAGER', 'Manager');
addEnum('UserRole', 'CREATIVE', 'Creative');
addEnum('NoteVisibility', 'PRIVATE', 'Private');
addEnum('NoteVisibility', 'CREATIVE_VISIBLE', 'Visible to Creatives');

export function statusEnumValues(statusEnum) {
  return Object.values(LABELS[statusEnum]);
}

export function statusEnumLabel(statusEnum, value) {
  return LABELS[statusEnum]?.[value]?.label || value;
}

export function statusEnumClasses(statusEnum, value) {
  return [
    kebabCase(statusEnum),
    value?.toLowerCase(),
  ];
}

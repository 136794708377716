<script setup>
import { useAuthStore } from '@/stores/auth';
import SigninStep from '@/components/wizard/SigninStep.vue';
import HeadshotsStep from '@/components/wizard/HeadshotsStep.vue';
import SkillsStep from '@/components/wizard/SkillsStep.vue';
import ActorProfileStep from '@/components/wizard/ActorProfileStep.vue';
import VideosStep from '@/components/wizard/VideosStep.vue';
import ShowsStep from '@/components/wizard/ShowsStep.vue';
import FinishedStep from '@/components/wizard/FinishedStep.vue';
import ONavbar from '@/components/Navbar.vue';
import ONavbarItem from '@/components/NavbarItem.vue';
import { computed, onMounted, ref } from 'vue';
import { showStatus } from '@/dialogs';
import { useWizardActorEditing, WizardActorEditingKey } from '@/composables/wizard_actor_editing.js';
import { provideLocal, injectLocal } from '@vueuse/core';

const authStore = useAuthStore();

const ready = ref(false);
const authenticated = ref(false);
const actorId = ref(null);
const sessionEmail = ref(null);
const tabPage = ref('profile');
const showFinished = ref(false);

// refs
const mainTabs = ref(null);
const navbar = ref(null);

const isActorSaved = computed(() => {
  return !!actorId.value;
});

provideLocal(WizardActorEditingKey, useWizardActorEditing(actorId, sessionEmail));
const { isDirty, saveActor, finishActor, registerSaveHook } = injectLocal(WizardActorEditingKey);

onMounted(() => {
  authStore.startup('wizard').then(() => {
    ready.value = true;
    if (authStore.valid) {
      signedin();
    }
  });
});

// methods

async function signedin() {
  authenticated.value = true;
  const { session, actor } = await authStore.retrieveSession({ withSkills: true });
  sessionEmail.value = session.wizard.email;
  actorId.value = actor?.id;
}

function actorSaved(actor) {
  if (actorId.value !== actor.id) {
    actorId.value = actor.id;
  }
  return true;
}

registerSaveHook(actorSaved);

async function saveAll() {
  const resp = await saveActor();
  if (resp) {
    showStatus({ message: 'Profile saved' });
  }
}

async function finishAndSubmit() {
  if (!actorId.value) return;

  if (await finishActor()) {
    showStatus({ message: 'Profile updates are submitted' });
    showFinished.value = true;
    sessionEmail.value = null;
    authenticated.value = false;
    await authStore.signOut();
    showStatus({ message: 'Signed out' });
  }
}

function switchTab(tab) {
  if (!tab.disabled) tabPage.value = tab.value;
}
</script>

<template lang="pug">
OLoading(:active="!ready")
FinishedStep(v-if="showFinished")
SigninStep(v-else-if="!authenticated" @signedin="signedin")
template(v-else)
  ONavbar(ref="navbar" fixed-top shadow)
    template(#brand)
      ONavbarItem(v-if="isDirty" tag="div")
        OButton(variant="danger" @click="saveAll") Save
        i.ml-2(v-if="isDirty") Unsaved changes
      ONavbarItem(v-else tag="div")
        OButton(
          v-tooltip="'Mark your profile as complete and ready for review'"
          variant="primary"
          @click="finishAndSubmit"
        ) Send for review
      ONavbarItem.is-clickable.is-hidden-desktop(tag="div")
        OButton(icon-right="angle-right" @click="navbar.toggleActive()") Next
    template(#start)
      ONavbarItem.navbar-tab(
        v-for="tab in mainTabs?.childItems"
        :key="tab.value"
        :class="{'navbar-tab-disabled': tab.disabled, 'navbar-tab-active': tab.value === tabPage}"
        @click="switchTab(tab)"
      )
        OIcon(v-if="tab.icon" :icon="tab.icon")
        | {{ tab.label }}
  br
  OTabs.hidden-tabs(
    ref="mainTabs"
    v-model="tabPage"
  )
    OTabItem(value="profile" icon="user" label="Personal information")
      ActorProfileStep
    OTabItem(value="headshots" :disabled="!isActorSaved" icon="user" label="Headshots")
      HeadshotsStep(v-if="isActorSaved" :actor-id="actorId")
    OTabItem(value="skills" :disabled="!isActorSaved" icon="user" label="Skills")
      SkillsStep(v-if="isActorSaved")
    OTabItem(value="videos" :disabled="!isActorSaved" icon="user" label="Videos/Reels")
      VideosStep(v-if="isActorSaved" :actor-id="actorId")
    OTabItem(value="shows" :disabled="!isActorSaved" icon="user" label="Shows")
      ShowsStep(v-if="isActorSaved" :actor-id="actorId")
    OTabItem(value="calendar" :disabled="!isActorSaved" icon="user" label="Calendar")
      p.notification.is-info.is-light
        | Coming soon
        br
        | You will be able to make off times which you are unavailable as well as see your calendar of assigned shows.
</template>

<style lang="scss">
@import "shared";
</style>

<style scoped lang="scss">
@import "bulma/sass/utilities/_all.sass";

.b-tabs.hidden-tabs {
  :deep(nav.tabs) {
    display: none;
  }
}

.navbar {
  .navbar-tab {
    border: 1px solid transparent;
    margin-top: 0.5em;
  }

  .navbar-tab-active {
    border-color: #dbdbdb;
    border-bottom-color: transparent;
  }

  .navbar-tab-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.navbar-item {
  span.icon {
    margin-right: 0.5em;
  }
}
</style>

import { graph } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils';

// region graphQL
const sessionQuery = graph.query(`($withSkills: Boolean = false, $withTags: Boolean = false) {
  session {
    wizard {
      actor { ...actorFull }
      email
      createdAt
    }
    user {
      ...userFull
    }
  }
}`);

// endregion

export function retrieve({ ...withOptions } = {}) {
  return sessionQuery({ ...withOptions })
    .catch(wrapGraphQLError)
    .then((data) => data.session);
}

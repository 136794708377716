import { graph, paramsWithRole } from '@/api/graph';
import { wrapGraphQLError } from '@/api/utils.js';

// region graphql
graph.fragment({
  actorBasic: `on Actor {
    id
    email
    name
    pronouns
    primaryActorHeadshotId
    location
    phone
    website
    height
    unionAffiliation
    malePresentingRole
    femalePresentingRole
    nonBinaryRole
    driversLicense
    additionalInformation
    agentInformation
    otherSkills
    isLocal
    skillRanking
    status
    skillIds
    ...actorResumeUrls
    submittedAt
    createdAt
    updatedAt
  }`,
  actorAdminFull: `on Actor {
    showRoles {
      ...showRoleForActor
    }
    showRoleProspects {
      ...showRoleProspectForActor
    }
    notes {
      ...actorNoteFull
    }
  }`,
  actorResumeUrls: `on Actor {
    resumeUrls { style url expiration fileName }
  }`,
  actorFull: `on Actor {
    ...actorBasic
    headshots {
      ...actorHeadshotBasic
    }
    videos {
      ...actorVideoBasic
    }
    skills @include(if: $withSkills) {
      ...skillBasic
    }
    tagIds @include(if: $withTags)
    tags @include(if: $withTags) {
      ...tagBasic
    }
  }`,
});

const listQuery = graph.query(`($limit: Int, $after: String, $isManager: Boolean = false) {
  actors(first: $limit, after: $after) {
    nodes {
     ...actorBasic
     tagIds @include(if: $isManager)
    }
    pageInfo { ...pageInfo }
  }
}`);

const retrieveQuery = graph.query(`($actorId: ActorID!, $isManager: Boolean = false, $withTags: Boolean = false, $withSkills: Boolean = false) {
  actor(actorId: $actorId) {
    ...actorFull
    ...actorAdminFull @include(if: $isManager)
  }
}`);

const retrieveResumeUrlsQuery = graph.query(`($actorId: ActorID!) {
  actor(actorId: $actorId) {
    id
    ...actorResumeUrls
  }
}`);

const createMutation = graph.mutate(`($input: CreateActorInput!) {
  createActor(input: $input) {
    actor { ...actorBasic }
  }
}`);

const updateMutation = graph.mutate(`($input: UpdateActorInput!, $isManager: Boolean = false) {
  updateActor(input: $input) {
    actor {
     ...actorBasic
     tagIds @include(if: $isManager)
    }
  }
}`);

const uploadResumeMutation = graph.mutate(`($input: UploadActorResumeInput!) {
  uploadActorResume(input: $input) {
    actor {
     id
     ...actorResumeUrls
    }
    presignedPost { ...presignedPost }
  }
}`);

const deleteMutation = graph.mutate(`($input: DeleteActorInput!) {
  deleteActor(input: $input) { actorId }
}`);

// endregion

export function list({ limit = 10, after = null } = {}) {
  return listQuery(paramsWithRole({ limit, after }))
    .catch(wrapGraphQLError);
}

export function retrieve({ actorId, ...withOptions } = {}) {
  return retrieveQuery(paramsWithRole({ actorId, ...withOptions }))
    .catch(wrapGraphQLError)
    .then((data) => data.actor);
}

export function retrieveResumeUrls({ actorId } = {}) {
  return retrieveResumeUrlsQuery({ actorId })
    .catch(wrapGraphQLError)
    .then((data) => data.actor);
}

export function create({ actor }) {
  return createMutation({ input: actor })
    .catch(wrapGraphQLError)
    .then(result => result.createActor);
}

export function update({ actorId, updates }) {
  return updateMutation(paramsWithRole({ input: { actorId, ...updates } }))
    .catch(wrapGraphQLError)
    .then(result => result.updateActor);
}

export function uploadResume({ actorId, ...options }) {
  return uploadResumeMutation({ input: { actorId, ...options } })
    .catch(wrapGraphQLError)
    .then(result => result.uploadActorResume);
}

function _delete({ actorId }) {
  return deleteMutation({ input: { actorId } })
    .catch(wrapGraphQLError)
    .then(result => result.deleteActor);
}

export { _delete as delete };

import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useShowRoleStore } from '@/stores/show_roles';
import { useSeasonStore } from '@/stores/seasons';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord, urlsExpired,
} from '@/utilities/helpers';
import { UploadManager } from '@/utilities/upload_manager';

export const useShowStore = defineStore('shows', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (showId) => state.records.find((v) => v.id === showId),
    isLoading: (state) => state.loading > 0,
    forSeasonId: (state) => (seasonId) => state.records.filter((v) => v.seasonId === seasonId),
  },
  actions: {
    fetchName(showId) {
      return showId ? this.retrieve(showId)?.name : undefined;
    },
    async processRecord(show) {
      await useShowRoleStore().processRecords(show.roles);
      if (show.season) {
        await useSeasonStore().processRecord(show.season);
      }
      return addUpdateRecord(this.records, show, { skip: ['roles', 'season'] });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(showId) {
      await useShowRoleStore().removeRecordsBy({ showId });

      removeRecord(this.records, showId);
    },
    async removeRecordsBy({ seasonId }) {
      if (seasonId) {
        const toRemove = this.forSeasonId(seasonId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    /**
     * @param {string} showId
     * @param {boolean} force
     * @param {Object} withOptions
     * @param {boolean} withOptions.withProspects
     * @param {boolean} withOptions.withActors
     * @return {Promise<Object>}
     */
    async retrieveAsync(showId, { force = false, ...withOptions } = {}) {
      let record = this.records.find((v) => v.id === showId);
      if (!record || force) {
        await doLoading(this, async () => {
          const params = { showId, ...withOptions };
          record = await api.shows.retrieve(params);
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async sync({ seasonId = null, flush = false } = {}) {
      if (flush) {
        const ids = this.records.map(r => r.id);
        ids.forEach(id => this.removeRecord(id));
      }
      const filters = {};
      if (seasonId) {
        filters.seasonId = seasonId;
      }
      await doLoading(this, async () => {
        await api.utils.paginateAll({
          fetch: api.shows.list,
          node: 'shows',
          filters: { limit: 100, ...filters },
        }, async (edge) => {
          await this.processRecord(edge.node);
        });
      });
    },
    async retrievePosterUrls(showId) {
      let record = this.records.find((v) => v.id === showId);
      await doLoading(this, async () => {
        if (!record) {
          record = await api.shows.retrieve({ showId });
          record = await this.processRecord(record);
        } else {
          if (urlsExpired(record.posterUrls)) {
            record = await api.shows.retrievePosterUrls({ showId });
            record = await this.processRecord(record);
          }
        }
      });
      return record;
    },
    async startPosterUpload({ showId, contentType, fileName }) {
      const initial = await api.shows.uploadPoster({
        showId, upload: { start: { contentType, fileName } },
      });
      await this.processRecord(initial.show);
      return new UploadManager(initial.presignedPost, async (completionToken) => {
        const data = await api.shows.uploadPoster({ showId, upload: { finish: { completionToken } } });
        await this.processRecord(data.show);
      });
    },
    async reorder(showId, position, relatedShowId = null) {
      if (isExisting(showId)) {
        const payload = {
          showId,
          position,
        };
        if (relatedShowId) {
          payload.relatedShowId = relatedShowId;
        }
        return await doLoading(this, async () => {
          return await api.shows.reorder(payload).then(async (data) => {
            await this.processRecords(data.updatedShows);
            return this.processRecord(data.show);
          });
        });
      } else {
        throw new Error('Show ID does not exist');
      }
    },
    async save(show) {
      if (isExisting(show.id)) {
        // existing
        const original = this.retrieve(show.id);
        const payload = {
          showId: show.id,
          updates: buildChanges(original, show, {
            skip: ['id', 'posterUrls'],
          }),
        };

        return await doLoading(this, async () => {
          if (Object.keys(payload.updates).length > 0) {
            return await api.shows.update(payload).then(async (data) => {
              return this.processRecord(data.show);
            });
          } else {
            return original;
          }
        });
      } else {
        // new show
        const prepared = {
          show: clearEmpty(show),
        };
        return await doLoading(this, async () => {
          return await api.shows.create(prepared).then(async (data) => {
            return this.processRecord(data.show);
          });
        });
      }
    },
    async delete(showId) {
      const show = this.retrieve(showId);
      if (!show) {
        return;
      }
      const payload = { showId };

      return await doLoading(this, async () => {
        return await api.shows.delete(payload).then(async data => {
          await this.removeRecord(data.showId);
          return data.showId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShowStore, import.meta.hot));
}

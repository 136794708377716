import { acceptHMRUpdate, defineStore } from 'pinia';
import * as authApi from '@/api/auth';
import { session as sessionApi } from '@/api';
import { useSessionStorage } from '@vueuse/core';
import { useActorStore } from '@/stores/actors';
import { useUserStore } from '@/stores/users';
import { doLoading } from '@/utilities/helpers';
import { SetErrorHandlerUser } from '@/error_handling';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { Authorization } from '@/utilities/authorization';
import { inject } from 'vue';

/**
 * Handles error response from the server
 *
 * @param store
 * @param callbackFn
 * @returns Promise<void>
 */
function handleError(store, callbackFn) {
  store.checking = true;
  return callbackFn().catch(e => {
    // TODO: handle 500 errors better
    setToken(store, null);
    store.valid = false;
    return Promise.reject(e);
  }).finally(() => {
    store.checking = false;
  });
}

function setToken(store, token) {
  const key = tokenKey(store.scope);
  store.auth[key] = token;
}

function tokenKey(scope) {
  if (scope) {
    return `${scope}_token`;
  } else {
    return 'token';
  }
}

const authHelperKey = Symbol('auth');

export class AuthHelper {
  can(rule, ...params) {
    return useAuthStore().can(rule, ...params);
  }

  any(...rules) {
    const authStore = useAuthStore();
    return rules.some((r) => authStore.can(r));
  }

  static install(app) {
    const authHelper = new AuthHelper();
    app.config.globalProperties.$auth = authHelper;
    app.provide(authHelperKey, authHelper);
  }
}

export function useAuthHelper() {
  return inject(authHelperKey);
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    auth: useSessionStorage('bnb-auth', {}),
    scope: null,
    userId: null,
    valid: false,
    type: null,
    role: null,
    checking: false,
    reauthenticate: false,
    initializer: null,
  }),
  getters: {
    user() {
      if (!this.userId) {
        return null;
      }

      return useUserStore().retrieve(this.userId);
    },
    token(state) {
      const key = tokenKey(state.scope);
      return state.auth[key];
    },
    tokenExpiresAt() {
      if (!this.token) return null;

      try {
        const decoded = jwtDecode(this.token);
        // remove 30 seconds of fuzz to account for clock drift
        return DateTime.fromSeconds(decoded.exp).minus({ seconds: 30 });
      } catch (ex) {
        return null;
      }
    },
    authorization() {
      if (!this.token) return null;

      return new Authorization(this.role);
    },
  },
  actions: {
    can(rule, ...params) {
      return this.authorization?.can(rule, ...params);
    },
    isTokenExpired() {
      if (!this.token) return true;

      return DateTime.now() > this.tokenExpiresAt;
    },
    startup(scope = null) {
      if (this.initializer) return this.initializer;

      this.scope = scope;

      this.initializer = new Promise((resolve, _reject) => {
        if (!this.token) return resolve();

        return this.validate(true)
          .then(() => this.valid = true)
          .catch(_e => null)
          .finally(() => {
            resolve();
          });
      });

      return this.initializer;
    },
    check() {
      if (!this.valid) return false;

      if (this.isTokenExpired()) {
        this.reauthenticate = true;
      }
      return this.reauthenticate;
    },
    authenticate(username, password) {
      return handleError(this, async () => {
        const response = await authApi.authenticate({ username, password });
        if (response.token) {
          setToken(this, response.token);
        }
        this.valid = true;
        this.reauthenticate = false;
        return true;
      });
    },
    authenticateWizard(email, { verify_email = null, otp = null, password = null } = {}) {
      return handleError(this, async () => {
        const params = { email };
        if (otp) params.token = otp;
        if (password) params.password = password;
        if (verify_email) params.verify_email = verify_email;
        const response = await authApi.authenticateWizard(params);
        if (response.token) {
          setToken(this, response.token);
        }
        this.valid = true;
        this.reauthenticate = false;
        return true;
      });
    },
    validate(initial = false) {
      if (!this.token || (!initial && !this.valid)) return Promise.reject({ error: 'no token' });

      return handleError(this, async () => {
        const response = await authApi.validate({ token: this.token });
        if (response.token) {
          setToken(this, response.token);
        }
        return true;
      });
    },
    async signOut() {
      setToken(this, null);
      this.userId = null;
      this.valid = false;
      this.type = null;
      this.role = null;
      this.reauthenticate = false;
      SetErrorHandlerUser(null);
    },
    async retrieveSession(withOptions = {}) {
      let session = null;
      let actor = null;
      await doLoading(this, async () => {
        session = await sessionApi.retrieve(withOptions);
        if (session.wizard) {
          if (session.wizard?.actor) {
            actor = await useActorStore().processRecord(session.wizard.actor);
          }
          this.type = 'wizard';
          SetErrorHandlerUser({ session: this.type, email: session.wizard.email });
        }
        if (session.user) {
          const user = await useUserStore().processRecord(session.user);
          this.userId = user.id;
          this.role = user.role;
          this.type = 'user';
          SetErrorHandlerUser({ session: this.role || this.type, email: session.user.email });
        }
      });
      return { session, actor };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
